import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { API_URL } from "../config/api_config";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { useAuth } from "../contexts/AuthContext";
import { Alert } from "../components/ui/alert";
import { FormLayout } from "./ui/formLayout";
import { validatePhoneNumber } from "../utils/validation";
import { ClientID, AccountInfo } from "../utils/sessionUtils";

const registerCoachWithAPI = async (
  phoneNumber,
  firebaseToken,
  firstName,
  lastName,
) => {
  const registerEndpoint = `${API_URL}/account/register`;

  const userData = {
    phone_number: {
      phone_number: phoneNumber,
    },
    first_name: firstName,
    last_name: lastName,
  };

  try {
    const response = await fetch(registerEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${firebaseToken}`,
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error("Failed to register coach");
    }

    sessionStorage.setItem(AccountInfo.CLIENT_ID, ClientID.COACH);

    return await response.json();
  } catch (error) {
    console.error("Error during API registration:", error);
    throw error;
  }
};

const registerTeacherWithAPI = async (
  phoneNumber,
  firebaseToken,
  firstName,
  lastName,
) => {
  const registerEndpoint = `${API_URL}/account/register/teacher`;

  const userData = {
    phone_number: {
      phone_number: phoneNumber,
    },
    first_name: firstName,
    last_name: lastName,
  };

  try {
    const response = await fetch(registerEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${firebaseToken}`,
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error("Failed to register teacher");
    }

    sessionStorage.setItem(AccountInfo.CLIENT_ID, ClientID.TEACHER);
    return await response.json();
  } catch (error) {
    console.error("Error during API registration:", error);
    throw error;
  }
};
export default function GoogleSignup() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const { currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientID = queryParams.get("clientID");

  // Validate if clientID exists
  React.useEffect(() => {
    if (!clientID) {
      setError(
        "Invalid access: clientID is required to complete registration.",
      );
    }
  }, [clientID]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure valid phone number
    const validationError = validatePhoneNumber(phoneNumber);
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      setError("");
      setLoading(true);

      // Get Firebase token and user info
      const token = await currentUser.getIdToken();
      const displayName = currentUser.displayName || "";
      const [firstName, lastName] = displayName.split(" ");

      // Register the user with API
      if (clientID === "coach") {
        await registerCoachWithAPI(phoneNumber, token, firstName, lastName);
      } else if (clientID === "teacher") {
        await registerTeacherWithAPI(phoneNumber, token, firstName, lastName);
      } else {
        setError("Cannot register with unknown clientID");
      }

      // Redirect to dashboard if registration is successful
      navigate("/");
    } catch {
      setError("Failed to complete registration");
    }
    setLoading(false);
  };

  return (
    <FormLayout title="Complete Your Registration">
      {error && <Alert message={error} />}
      <form
        onSubmit={handleSubmit}
        className="bg-white bg-opacity-0 rounded-lg p-8 backdrop-filter backdrop-blur-md"
      >
        <div className="space-y-4">
          <Input
            type="text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
          />
        </div>
        <div className="flex justify-center w-full mt-6">
          <Button
            disabled={loading}
            type="submit"
            variant="ghost"
            className="w-1/2 text-white font-light rounded-full border border-transparent hover:border-white hover:bg-transparent hover:text-white transition-all duration-200 shadow-submit"
          >
            Complete Registration
          </Button>
        </div>
      </form>
    </FormLayout>
  );
}
