import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import Header from "../components/Header";
import { Button } from "../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog";

export default function CoachConfigDashboard() {
  const [coachData, setCoachData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [modalError, setModalError] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const { currentUser, logout } = useAuth();

  useEffect(() => {
    fetchCoachData();
  }, []);

  const fetchCoachData = async () => {
    setLoading(true);
    setError("");
    try {
      const token = await currentUser.getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/account/info`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const data = response.data;
      setCoachData({
        ...data,
        phone_number: data.phone_number.phone_number,
      });
    } catch (err) {
      setError("Failed to fetch coach data");
    } finally {
      setLoading(false);
    }
  };

  const openEditModal = () => {
    setEditData(coachData);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditData(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    setModalError("");
    try {
      const token = await currentUser.getIdToken();
      const updatedData = {
        ...editData,
        phone_number: { phone_number: editData.phone_number },
      };
      await axios.put(
        `${process.env.REACT_APP_API_URL}/account/update/coach`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      closeEditModal();
      fetchCoachData();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setModalError(error.response.data.detail);
      } else {
        setModalError("Failed to update coach data");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setError("");
    try {
      await logout();
    } catch {
      setError("Failed to log out");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative">
      {/* Backgrounds */}
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />

      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-10">
        <Header
          toggleUnderTheHood={null}
          toggleChat={null}
          onLogout={handleLogout}
          currentUser={currentUser}
          hasNewMessage={false}
        />
      </div>

      <main
        className="container mx-auto p-6 flex-grow flex flex-col items-center"
        style={{ paddingTop: "100px" }}
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-8">
          Coach Configuration
        </h1>
        <div className="w-full max-w-5xl bg-white bg-opacity-40 rounded-lg shadow-lg p-6">
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {loading && <p>Loading...</p>}
          {coachData && (
            <div className="bg-white bg-opacity-30 rounded-lg overflow-hidden shadow-lg p-6">
              <p>
                <strong>First Name:</strong> {coachData.first_name}
              </p>
              <p>
                <strong>Last Name:</strong> {coachData.last_name}
              </p>
              <p>
                <strong>Phone Number:</strong> {coachData.phone_number}
              </p>
              <p>
                <strong>Observation Doc ID:</strong>{" "}
                {coachData.observation_doc_id || "None"}
              </p>
              <Button onClick={openEditModal}>Edit</Button>
            </div>
          )}
        </div>
      </main>

      {isEditModalOpen && (
        <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Coach Information</DialogTitle>
              {modalError && (
                <p className="text-red-500 text-sm mt-2">{modalError}</p>
              )}
            </DialogHeader>
            <div className="space-y-4">
              <div>
                <label className="block mb-1 font-semibold">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={editData.first_name || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={editData.last_name || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">Phone Number</label>
                <input
                  type="text"
                  name="phone_number"
                  value={editData.phone_number || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">
                  Observation Doc ID
                </label>
                <input
                  type="text"
                  name="observation_doc_id"
                  value={editData.observation_doc_id || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
            </div>
            <DialogFooter>
              <Button onClick={closeEditModal}>Cancel</Button>
              <Button onClick={handleSave} disabled={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
