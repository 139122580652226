import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Save, Mail, ArrowLeft } from "lucide-react";

interface LessonPlanViewerProps {
  lessonPlan: string;
  onClose: () => void;
}

const LessonPlanViewer: React.FC<LessonPlanViewerProps> = ({
  lessonPlan,
  onClose,
}) => {
  const handleSave = () => {
    const blob = new Blob([lessonPlan], { type: "text/markdown" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "lesson_plan.md";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleEmail = () => {
    const subject = encodeURIComponent("Lesson Plan");
    const body = encodeURIComponent(lessonPlan);
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  return (
    <div className="lesson-plan-viewer">
      <h1 className="lesson-plan-title">Generated Lesson Plan</h1>
      <div className="lesson-plan-content">
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{lessonPlan}</ReactMarkdown>
      </div>
      <div className="lesson-plan-actions">
        <button className="action-btn save-btn" onClick={handleSave}>
          <Save size={18} />
          Save to Disk
        </button>
        <button className="action-btn email-btn" onClick={handleEmail}>
          <Mail size={18} />
          Send as Email
        </button>
        <button className="action-btn back-btn" onClick={onClose}>
          <ArrowLeft size={18} />
          Back to Builder
        </button>
      </div>
    </div>
  );
};

export default LessonPlanViewer;
