import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../components/ui/alert";
import { useAuth } from "../../../contexts/AuthContext";
import { Header } from "../Header";
import { fetchRssLink } from "../../../api/nisaAPI";
import { getCachedUID } from "../../../utils/sessionUtils";
import { Button } from "../../../components/ui/button";

const TeacherDashboard: React.FC = () => {
  const [error, setError] = useState<string>("");
  const [rssLink, setRssLink] = useState<string | null>(null);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  };

  const handleFetchRssLink = async () => {
    setError("");

    // Toggle behavior: If rssLink is already set, clicking hides it
    if (rssLink) {
      setRssLink(null);
      return;
    }

    try {
      const accessToken = await currentUser?.getIdToken();
      if (!accessToken)
        throw new Error("No access token. Log out + log back in.");

      const teacherId = getCachedUID();
      if (!teacherId) {
        throw new Error("Teacher ID is missing. Log out + log back in.");
      }
      const link = await fetchRssLink(teacherId, accessToken);
      setRssLink(link);
    } catch (err) {
      setError("Failed to fetch RSS link");
    }
  };

  const handleCopyLink = () => {
    if (rssLink) {
      navigator.clipboard.writeText(rssLink);
      alert("RSS link copied to clipboard");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative">
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />

      <div className="fixed top-0 left-0 right-0 z-10">
        <Header onLogout={handleLogout} />
      </div>

      {error && (
        <div className="fixed top-16 z-20 w-full flex justify-center">
          <Alert message={error} className="max-w-lg" />
        </div>
      )}

      <main
        className="container mx-auto p-4 flex-grow flex flex-col items-center"
        style={{ paddingTop: "75px" }}
      >
        <div className="mb-4">
          <Button onClick={handleFetchRssLink}>Get RSS Feed</Button>
        </div>
        {rssLink && (
          <div className="w-full max-w-xl bg-gray-100 p-6 rounded-md shadow-md">
            <p
              className="text-gray-700 break-words overflow-hidden text-ellipsis whitespace-pre-wrap"
              style={{ wordBreak: "break-all" }}
              title={rssLink} // Tooltip for full link
            >
              <b>RSS Link:</b> {rssLink}
            </p>
            <div className="mt-4 flex justify-center">
              <Button onClick={handleCopyLink}>Copy to Clipboard</Button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default TeacherDashboard;
