import { useState, useEffect, useMemo } from "react";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";

const LessonSelector = ({ lessonSource, onLessonChange }) => {
  const [lessons, setLessons] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [selectedLesson, setSelectedLesson] = useState("");
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchLessonsBySource = async () => {
      try {
        const token = await currentUser.getIdToken();
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/${lessonSource}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setLessons(response.data);
      } catch (err) {
        console.error("Failed to fetch lessons:", err);
      }
    };
    // Reset downstream selections when source changes
    setSelectedGrade("");
    setSelectedModule("");
    setSelectedLesson("");

    fetchLessonsBySource();
  }, [lessonSource, currentUser]);

  const GRADE_ORDER = ["K", "1", "2", "3", "4", "5", "6", "7", "8", "HS"];

  // Compute unique, sorted grades
  const grades = useMemo(() => {
    return [...new Set(lessons.map((lesson) => lesson.grade))].sort(
      (a, b) => GRADE_ORDER.indexOf(a) - GRADE_ORDER.indexOf(b),
    );
  }, [lessons]);

  // Compute modules for the selected grade
  const modules = useMemo(() => {
    return [
      ...new Set(
        lessons
          .filter((lesson) => lesson.grade === selectedGrade)
          .map((lesson) => lesson.module_name),
      ),
    ];
  }, [lessons, selectedGrade]);

  // Compute the final filtered lessons based on grade and module
  const filteredLessons = useMemo(() => {
    return lessons
      .filter(
        (lesson) =>
          lesson.grade === selectedGrade &&
          lesson.module_name === selectedModule,
      )
      .sort((a, b) => {
        // First, sort by topic_name
        const topicComparison = a.topic_name.localeCompare(b.topic_name);
        if (topicComparison !== 0) return topicComparison;

        // If topic_names are equal, sort by display_name
        return a.display_name.localeCompare(b.display_name);
      });
  }, [lessons, selectedGrade, selectedModule]);

  const handleLessonChange = (lessonId) => {
    setSelectedLesson(lessonId);
    if (onLessonChange) {
      onLessonChange(lessonId);
    }
  };

  return (
    <div>
      <div>
        <label className="block mb-1 font-semibold">Grade</label>
        <select
          name="grade"
          value={selectedGrade}
          onChange={(e) => {
            setSelectedGrade(e.target.value);
            // Reset downstream selections when grade changes
            setSelectedModule("");
            setSelectedLesson("");
          }}
          className="w-full p-2 border rounded"
          required
        >
          <option value="" disabled>
            Select a grade
          </option>
          {grades.map((grade) => (
            <option key={grade} value={grade}>
              {grade}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block mt-4 mb-1 font-semibold">Module</label>
        <select
          name="module"
          value={selectedModule}
          onChange={(e) => {
            setSelectedModule(e.target.value);
            setSelectedLesson("");
          }}
          className="w-full p-2 border rounded"
          required
        >
          <option value="" disabled>
            Select a module
          </option>
          {modules.map((module) => (
            <option key={module} value={module}>
              {module}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block mt-4 mb-1 font-semibold">Lesson</label>
        <select
          name="lesson"
          value={selectedLesson}
          onChange={(e) => handleLessonChange(e.target.value)}
          className="w-full p-2 border rounded"
          disabled={!selectedModule || filteredLessons.length === 0}
          required
        >
          <option value="" disabled>
            {selectedModule ? "Select a lesson" : "Select a module first"}
          </option>
          {filteredLessons.map((lesson) => (
            <option key={lesson.id} value={lesson.id}>
              {lesson.display_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LessonSelector;
