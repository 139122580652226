import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";
import ConfigDashboard from "./components/ConfigDashboard";
import FirebaseRoute from "./components/FirebaseRoute";
import GoogleSignup from "./components/GoogleSignup";
import { Outlet } from "react-router-dom";
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import Signup from "./components/Signup";
import TeacherSignup from "./components/TeacherSignup";
import TeacherDashboard from "./components/Teacher/pages/HomePage";
import RoleBasedRoute from "./components/RoleBasedRoute";

import UploadLessonPlans from "./components/LessonConfigDashboard";
import CoachConfigDashboard from "./components/CoachConfigDashboard";
import featureFlags from "./utils/featureFlags";
import NotFoundPage from "./components/NotFoundPage";
import LessonBuilderPage from "./components/Teacher/pages/LessonBuilder/LessonBuilderPage";
import LessonBuilderLessonPage from "./components/Teacher/pages/LessonBuilderLesson/LessonBuilderLessonPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CoachProvider } from "./contexts/CoachProvider";

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Routes>
            {/* Public Routes */}
            <Route path="/signup" element={<Signup />} />
            {featureFlags.enableTeacherRoutes && (
              <Route path="/signup/teacher" element={<TeacherSignup />} />
            )}
            <Route path="/login" element={<Login />} />

            {/* Firebase routes, require firebase token but no api authorization */}
            <Route element={<FirebaseRoute />}>
              <Route exact path="/google_signup" element={<GoogleSignup />} />
            </Route>
            {/* Teacher Private Routes, requiring authentication + api authorization */}
            {featureFlags.enableTeacherRoutes && (
              <Route element={<RoleBasedRoute allowedRoles={["teacher"]} />}>
                <Route exact path="/teacher" element={<TeacherDashboard />} />
                <Route
                  exact
                  path="/lesson_config"
                  element={<UploadLessonPlans />}
                />
                <Route
                  exact
                  path="/teacher/lesson_builder"
                  element={<LessonBuilderPage />}
                />
                <Route
                  exact
                  path="/teacher/lesson_builder/:id"
                  element={<LessonBuilderLessonPage />}
                />
              </Route>
            )}
            <Route element={<RoleBasedRoute allowedRoles={["coach"]} />}>
              <Route element={<CoachRoutes />}>
                <Route exact path="/" element={<HomePage />} />
                <Route
                  exact
                  path="/teacher_config"
                  element={<ConfigDashboard />}
                />
                <Route
                  exact
                  path="/coach/config"
                  element={<CoachConfigDashboard />}
                />
                <Route
                  exact
                  path="/lesson_config"
                  element={<UploadLessonPlans />}
                />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </QueryClientProvider>
      </AuthProvider>
    </Router>
  );
}

// Ensure Coach Routes are wrapped in Coach Provider
const CoachRoutes = () => {
  return (
    <CoachProvider>
      <Outlet />
    </CoachProvider>
  );
};

export default App;
