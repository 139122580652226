export const gradeOptions = [
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
export const subjectOptions = ["English", "Mathematics", "Science"];
