import { useEffect, useRef, useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import { io, Socket } from "socket.io-client";

interface Message {
  type: "user" | "bot" | "error";
  content: string;
}

interface ChatboxPropsBase {
  onNewMessage: () => void;
  isReadOnly: boolean;
  eventType: string;
  initialMessages?: Message[];
  title?: string;
  subtitle?: string;
}

type ChatboxProps =
  | (ChatboxPropsBase & {
      isReadOnly: false;
      currentWeek: Date;
    })
  | (ChatboxPropsBase & {
      isReadOnly: true;
      currentWeek?: Date;
    });

const Chatbox: React.FC<ChatboxProps> = ({
  onNewMessage,
  isReadOnly,
  currentWeek,
  eventType = "web_response",
  initialMessages = [],
  title = "nisa.chat",
  subtitle = "pick up where you left off. nisa can see the dashboard.",
}) => {
  const [messages, setMessages] = useState<Message[]>(initialMessages);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const socketRef = useRef<Socket | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    socketRef.current = io(`${process.env.REACT_APP_API_URL}`, {
      transports: ["websocket"],
      auth: {
        token: `${currentUser.accessToken}`,
      },
    });

    socketRef.current.on("log_event", (data) => {
      if (data.type === eventType) {
        handleAsyncBotMessage(data.content);
      }
    });

    socketRef.current.on("connect_error", (error) => {
      console.error("Socket.IO Error:", error);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const handleAsyncBotMessage = (content: string) => {
    const botMessage: Message = { type: "bot", content: content };
    setMessages((prevMessages) => [...prevMessages, botMessage]);
    onNewMessage();
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    const newMessage: Message = { type: "user", content: inputMessage };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputMessage("");
    setIsLoading(true);

    try {
      let payload = {
        message: inputMessage,
        week_start: currentWeek!.toISOString().split("T")[0],
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/chat/message`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        },
      );
      if (response.status !== 200) {
        const errorMessage: Message = {
          type: "error",
          content: "Failed to send message. Please try again.",
        };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
        onNewMessage();
      }
    } catch (error) {
      console.error("Error sending message:", error);
      const errorMessage: Message = {
        type: "error",
        content: "Failed to send message. Please try again.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative z-0 flex flex-col max-h-[50vh] h-full">
      <div className="absolute inset-0 bg-white bg-opacity-30 backdrop-filter backdrop-blur-md rounded-lg"></div>
      <div
        className="relative bg-transparent rounded-lg overflow-hidden flex flex-col h-full"
        style={{
          boxShadow:
            "inset 0 0 15px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(255, 255, 255, 0.1)",
        }}
      >
        <div className="bg-gray-50 bg-opacity-50 border-b border-gray-200 border-opacity-50 p-4">
          <h2 className="text-xl font-light text-gray-800">{title}</h2>
          <p className="text font-light text-gray-400">{subtitle}</p>
        </div>
        <div className="flex-grow overflow-y-auto p-4 relative">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`mb-4 ${message.type === "user" ? "text-right" : "text-left"}`}
            >
              <div
                className={`inline-block p-2 rounded-lg ${
                  message.type === "user"
                    ? "bg-blue-500 text-white"
                    : message.type === "bot"
                      ? "bg-gray-200 text-gray-800"
                      : "bg-red-500 text-white"
                }`}
              >
                {message.content}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        {!isReadOnly && (
          <form
            onSubmit={handleSendMessage}
            className="p-4 bg-gray-50 bg-opacity-50 border-t border-gray-200 border-opacity-50"
            style={{ flexShrink: 0 }}
          >
            <div className="flex">
              <Input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type your message..."
                className="flex-grow mr-2"
                disabled={isLoading}
              />
              <Button type="submit" disabled={isLoading}>
                {isLoading ? "nisa is responding..." : "Send"}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Chatbox;
