import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import BrainstormSpace from "./BrainstormSpace";
import LessonBuilderSpace from "./LessonBuilderSpace";
import Chatbox from "../../../Chatbox";
import LessonPlanViewer from "./LessonPlanViewer";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Skeleton } from "../../../ui/skeleton";
import { Button } from "../../../ui/button";
import { Lesson, LessonSegment, LessonSegmentGroup } from "./types";
import "./LessonBuilderLessonPage.css";
import { useAuth } from "../../../../contexts/AuthContext";

const DEBOUNCE_DELAY = 100;

function App() {
  const [segmentGroups, setSegmentGroups] = useState<LessonSegmentGroup[]>([]);
  const lastDropTime = useRef<number>(0);
  const [showLessonPlan, setShowLessonPlan] = useState<boolean>(false);
  const [lessonPlan, setLessonPlan] = useState<string>("");
  const initializedRef = useRef<boolean>(false);
  const { currentUser } = useAuth();

  const { id: lessonId } = useParams();

  const {
    isPending,
    error,
    data: lesson,
  } = useQuery({
    queryKey: ["lesson", lessonId],
    queryFn: async (): Promise<Lesson> => {
      const token = await currentUser.getIdToken();
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/lesson-builder/lessons/${lessonId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return res.data;
    },
  });

  useEffect(() => {
    if (lesson?.segment_groups && !initializedRef.current) {
      setSegmentGroups(lesson.segment_groups);
      initializedRef.current = true;
    }
  }, [lesson]);

  const { mutateAsync: updateLesson, isPending: isUpdating } = useMutation({
    mutationFn: async () => {
      const token = await currentUser.getIdToken();
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/lesson-builder/lessons/${lessonId}`,
        {
          segment_groups: segmentGroups,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return res.data;
    },
  });

  const { mutateAsync: buildLesson, isPending: isBuilding } = useMutation({
    mutationFn: async () => {
      const token = await currentUser.getIdToken();
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/lesson-builder/lessons/${lessonId}/build`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(res.data);
      return res.data;
    },
  });

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    segment: LessonSegment,
    fromBuilder: boolean,
    rowIndex: number,
    segmentIndex: number,
  ) => {
    const dragData = JSON.stringify({
      segment,
      fromBuilder,
      rowIndex,
      segmentIndex,
    });

    e.dataTransfer.setData("application/json", dragData);
  };

  const handleDrop = async (
    e: React.DragEvent,
    targetRowIndex: number,
    targetSegmentIndex: number,
  ) => {
    e.preventDefault();

    const now = Date.now();
    if (now - lastDropTime.current < DEBOUNCE_DELAY) {
      console.log("Debounced drop event");
      return;
    }
    lastDropTime.current = now;

    const data = JSON.parse(e.dataTransfer.getData("application/json"));
    const {
      segment,
      fromBuilder,
      rowIndex: sourceRowIndex,
      segmentIndex: sourceSegmentIndex,
    } = data;

    console.log("Processing drop event:", {
      targetRowIndex,
      targetSegmentIndex,
      fromBuilder,
      sourceRowIndex,
      sourceSegmentIndex,
    });

    if (!segment) {
      console.error("Attempted to drop undefined segment");
      return;
    }

    setSegmentGroups((prev: LessonSegmentGroup[]) => {
      console.log("Previous builder segments:", prev);

      let newSegmentGroups = [...prev];

      if (fromBuilder) {
        // Move segment within the builder
        if (sourceRowIndex !== undefined && sourceSegmentIndex !== undefined) {
          const [movedSegment] = newSegmentGroups[
            sourceRowIndex
          ].segments.splice(sourceSegmentIndex, 1);

          if (targetRowIndex === newSegmentGroups.length) {
            // Create a new row
            newSegmentGroups.push({ id: null, segments: [movedSegment] });
          } else {
            // Insert into existing row
            newSegmentGroups[targetRowIndex].segments.splice(
              targetSegmentIndex,
              0,
              movedSegment,
            );
          }
        }
      } else {
        // Create a new segment object with the same attributes as the dragged segment
        const newSegment = {
          ...segment,
          id: null,
        };

        if (targetRowIndex === newSegmentGroups.length) {
          // Create a new row
          newSegmentGroups.push({ id: null, segments: [newSegment] });
        } else {
          // Insert into existing row
          newSegmentGroups[targetRowIndex].segments.splice(
            targetSegmentIndex,
            0,
            newSegment,
          );
        }
      }

      // Remove empty rows
      newSegmentGroups = newSegmentGroups.filter(
        (row) => row.segments.length > 0,
      );

      console.log("New builder segments:", newSegmentGroups);
      return newSegmentGroups;
    });

    await updateLesson();
  };

  const handleAddBlankSegment = (rowIndex: number) => {
    const blankSegment: LessonSegment = {
      id: null,
      segment_type: "custom",
      segment_title: "Custom Segment",
      short_text: "Click to edit this custom segment",
      full_text: "Click to edit this custom segment",
      coach_related: false,
    };

    setSegmentGroups((prev) => {
      const newSegmentGroups = [...prev];
      if (rowIndex === newSegmentGroups.length) {
        newSegmentGroups.push({ id: null, segments: [blankSegment] });
      } else {
        newSegmentGroups[rowIndex].segments.push(blankSegment);
      }
      return newSegmentGroups;
    });
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleSegmentUpdate = async (
    rowIndex: number,
    segmentIndex: number,
    newContent: string,
  ) => {
    setSegmentGroups((prev) => {
      const newSegmentGroups = [...prev];
      newSegmentGroups[rowIndex] = {
        ...prev[rowIndex],
      };
      newSegmentGroups[rowIndex].segments[segmentIndex] = {
        ...newSegmentGroups[rowIndex].segments[segmentIndex],
        full_text: newContent,
      };
      return newSegmentGroups;
    });
    await updateLesson();
  };

  const handleDeleteSegment = async (
    rowIndex: number,
    segmentIndex: number,
  ) => {
    setSegmentGroups((prev) => {
      const newSegmentGroups = [...prev];
      newSegmentGroups[rowIndex].segments = newSegmentGroups[
        rowIndex
      ].segments.filter((_, index) => index !== segmentIndex);
      if (newSegmentGroups[rowIndex].segments.length === 0) {
        newSegmentGroups.splice(rowIndex, 1);
      }
      return newSegmentGroups;
    });

    await updateLesson();
  };

  const stopAllAudio = () => {
    const audios = document.getElementsByTagName("audio");
    for (let i = 0; i < audios.length; i++) {
      const audio = audios[i];
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const handleCloseLessonPlan = useCallback(() => {
    stopAllAudio();
    setShowLessonPlan(false);
  }, []);

  useEffect(() => {
    return () => {
      stopAllAudio();
    };
  }, []);

  const handleBuildLesson = async () => {
    try {
      const { lesson_plan } = await buildLesson();
      setLessonPlan(lesson_plan);
      setShowLessonPlan(true);
    } catch (error) {
      // TODO: Toast notifications for this case would be nice
      console.error("Error building lesson:", error);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isPending) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        <p className="mt-4 text-lg text-gray-900">Loading...</p>
      </div>
    );
  }

  return (
    <div>
      {showLessonPlan ? (
        <LessonPlanViewer
          lessonPlan={lessonPlan}
          onClose={handleCloseLessonPlan}
        />
      ) : (
        <>
          <div
            className="fixed inset-0 z-[-2]"
            style={{
              background: "#cdf0e4",
              backgroundAttachment: "fixed",
            }}
          />
          <div
            className="fixed inset-0 z-[-1] opacity-75"
            style={{
              backgroundImage: `url('https://www.transparenttextures.com/patterns/paper-2.png')`,
              backgroundRepeat: "repeat",
            }}
          />
          <div className="bg-white bg-opacity-25 p-4 mb-5 rounded-lg shadow-md text-center">
            {lesson ? (
              <h2>{lesson.overview}</h2>
            ) : (
              <Skeleton className="h-4 w-10" />
            )}
          </div>
          <div className="app-container">
            <div className="left-panel">
              {lesson && (
                <BrainstormSpace
                  lesson={lesson}
                  onDragStart={handleDragStart}
                />
              )}
              {lesson && (
                <Chatbox
                  isReadOnly
                  initialMessages={lesson.chat_histories.map((message) => ({
                    type: "bot",
                    content: message.message,
                  }))}
                  onNewMessage={() => {}}
                  eventType="lesson_builder_analysis"
                  title="nisa.chat"
                  subtitle="nisa can see your lesson segments and will chime in with thoughts"
                />
              )}
            </div>
            <div className="right-panel">
              <LessonBuilderSpace
                segmentGroups={segmentGroups}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragStart={handleDragStart}
                onSegmentUpdate={handleSegmentUpdate}
                onAddBlankSegment={handleAddBlankSegment}
                onDeleteSegment={handleDeleteSegment}
              />
              {segmentGroups.length > 0 && (
                <Button onClick={handleBuildLesson}>Build Lesson</Button>
              )}
            </div>
          </div>
        </>
      )}
      {isBuilding && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
          <p className="text-white text-2xl font-semibold">
            Building your lesson...
          </p>
        </div>
      )}
    </div>
  );
}

export default App;
