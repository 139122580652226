import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  ClientID,
  AccountInfo,
  getCachedClientID,
} from "../utils/sessionUtils";
import { fetchAndCacheAccountInfo } from "../utils/sessionUtils";

type RoleBasedRouteProps = {
  // Ensure allowedRoles are of the correct type
  allowedRoles: ClientID[];
};

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({ allowedRoles }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Check if role is cached in sessionStorage
        const cachedClientID = sessionStorage.getItem(
          AccountInfo.CLIENT_ID,
        ) as ClientID | null;

        if (cachedClientID) {
          if (allowedRoles.includes(cachedClientID)) {
            // Client is authorized for this page
            setRedirectPath(null);
          } else {
            // Redirect to base page if role doesn't match allowedRoles
            setRedirectPath(getBasePageForClientID(cachedClientID));
          }
          setIsLoading(false);
          return;
        }

        await fetchAndCacheAccountInfo(currentUser?.accessToken);
        const clientID = getCachedClientID();

        if (clientID && Object.values(ClientID).includes(clientID)) {
          sessionStorage.setItem(AccountInfo.CLIENT_ID, clientID);

          if (allowedRoles.includes(clientID)) {
            // Client is authorized for this page
            setRedirectPath(null);
          } else {
            setRedirectPath(getBasePageForClientID(clientID));
          }
        } else {
          setRedirectPath("/login");
          console.error("Invalid client_id received:", clientID);
        }
      } catch (error) {
        console.error("Authorization failed:", error);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [allowedRoles, currentUser]);

  if (isLoading) return <div>Loading...</div>;

  if (redirectPath) {
    return <Navigate to={redirectPath} state={{ from: location }} />;
  }

  return <Outlet />;
};

const getBasePageForClientID = (clientID: ClientID): string => {
  switch (clientID) {
    case ClientID.TEACHER:
      return "/teacher";
    case ClientID.COACH:
      return "/";
    default:
      // Fallback for invalid or unrecognized ClientID
      // Consider logging them out?
      return "/login";
  }
};

export default RoleBasedRoute;
