import { useEffect, useState } from "react";
import TeacherTable from "../components/TeacherTable";
import AddTeacherModal from "../components/AddTeacherModal";
import AddTeachersCSVModal from "../components/AddTeachersCSVModal";

import Header from "../components/Header";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { useCoach } from "../contexts/CoachProvider";

export default function ConfigDashboard() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);

  const { currentUser, logout } = useAuth();
  const { teachers, teachersMissingLessons, refreshTeacherData } = useCoach();
  const navigate = useNavigate();

  const fetchTeachers = async () => {
    setLoading(true);
    setError("");
    try {
      await refreshTeacherData();
    } catch {
      setError("Failed to fetch teacher data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  const handleTeacherAdded = () => {
    fetchTeachers();
    setIsModalOpen(false);
  };

  const handleCSVUploaded = () => {
    fetchTeachers();
  };

  async function handleLogout() {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative">
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />

      <div className="fixed top-0 left-0 right-0 z-10">
        <Header
          toggleUnderTheHood={null}
          toggleChat={null}
          onLogout={handleLogout}
          currentUser={currentUser}
          hasNewMessage={false}
        />
      </div>

      <main
        className="container mx-auto p-6 flex-grow flex flex-col items-center"
        style={{ paddingTop: "100px" }}
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-8">
          Teacher Configuration Dashboard
        </h1>
        {/* Show warning if teachers are missing lesson materials */}
        {/* Show warning with teacher names if missing lesson materials */}
        {teachersMissingLessons.length > 0 && (
          <div className="w-full max-w-5xl bg-yellow-100 border-l-4 border-yellow-500 text-yellow-800 p-4 mb-4 rounded">
            ⚠️{" "}
            <strong>
              The following teacher(s) don't have assigned lesson materials:
            </strong>
            <ul className="mt-2 ml-4 list-disc">
              {teachersMissingLessons.map((teacher) => (
                <li key={teacher.id} className="font-medium">
                  {teacher.first_name} {teacher.last_name}
                </li>
              ))}
            </ul>
            <p className="mt-2">This will affect the quality of support.</p>
          </div>
        )}
        <div className="w-full max-w-5xl bg-white bg-opacity-40 rounded-lg shadow-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">Teacher Data</h2>
            <div className="flex space-x-2">
              <Button onClick={() => setIsModalOpen(true)}>Add Teacher</Button>
              <Button onClick={() => setIsCSVModalOpen(true)}>
                Add Teachers CSV
              </Button>
              <Button onClick={fetchTeachers} disabled={loading}>
                {loading ? "Refreshing..." : "Refresh"}
              </Button>
            </div>
          </div>
          <TeacherTable
            teachers={teachers}
            error={error}
            onRefresh={fetchTeachers}
          />
        </div>
      </main>

      {isModalOpen && (
        <AddTeacherModal
          onClose={() => setIsModalOpen(false)}
          onTeacherAdded={handleTeacherAdded}
        />
      )}
      {isCSVModalOpen && (
        <AddTeachersCSVModal
          onClose={() => setIsCSVModalOpen(false)}
          onCSVUploaded={handleCSVUploaded}
        />
      )}
    </div>
  );
}
