import {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
  ReactNode,
} from "react";
import { TeacherInfo, fetchAllTeachers } from "../api/nisaAPI";
import { useAuth } from "./AuthContext";

interface CoachContextType {
  teachers: TeacherInfo[];
  teachersMissingLessons: TeacherInfo[];
  refreshTeacherData: () => Promise<void>;
}

interface CoachProviderProps {
  children: ReactNode;
}

const CoachContext = createContext<CoachContextType | null>(null);

export const CoachProvider = ({ children }: CoachProviderProps) => {
  const [teachers, setTeachers] = useState<TeacherInfo[]>([]);
  const { currentUser } = useAuth();

  // Fetch teacher data for the coach
  const refreshTeacherData = async () => {
    try {
      const token = await currentUser.getIdToken();
      const teacherData = await fetchAllTeachers(token);
      setTeachers(teacherData);
    } catch (error) {
      console.error("Failed to fetch coach data:", error);
    }
  };

  // Fetch initial coach data on mount
  useEffect(() => {
    if (currentUser) refreshTeacherData();
  }, [currentUser]);

  // Memoized List of Teachers Missing Lesson Materials
  const teachersMissingLessons = useMemo(() => {
    return teachers.filter(
      (teacher) => !teacher.last_lesson_id || !teacher.last_lesson_source,
    );
  }, [teachers]);

  return (
    <CoachContext.Provider
      value={{ teachers, teachersMissingLessons, refreshTeacherData }}
    >
      {children}
    </CoachContext.Provider>
  );
};

// Custom Hook for Using Coach Data
export const useCoach = (): CoachContextType => {
  const context = useContext(CoachContext);
  if (!context) {
    throw new Error("useCoach must be used within a CoachProvider");
  }
  return context;
};
