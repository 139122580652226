import { ReactNode } from "react";

type BaseHeaderProps = {
  children: ReactNode;
};

export function BaseHeader({ children }: BaseHeaderProps): JSX.Element {
  return (
    <header
      className="bg-white-600 bg-opacity-80
                 backdrop-filter backdrop-blur-sm text-white
                 p-4 flex justify-between items-center
                 relative z-10"
    >
      <h1 className="text-2xl font-light">
        <b>nisa</b> _controlcenter
      </h1>
      <div className="flex items-center space-x-4">{children}</div>
    </header>
  );
}
