import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { io } from "socket.io-client";

const UnderTheHood = () => {
  const [logs, setLogs] = useState([]);
  const logContainerRef = useRef(null);
  const { currentUser } = useAuth();
  const socketRef = useRef(null);

  useEffect(() => {
    // Initialize Socket.IO connection
    socketRef.current = io(`${process.env.REACT_APP_API_URL}`, {
      transports: ["websocket"],
      auth: {
        token: `${currentUser.accessToken}`,
      },
    });

    console.log("Initialized socketRef");
    // Listen for 'log_event' messages
    socketRef.current.on("log_event", (data) => {
      if (data.type !== "ping" && data.type !== "web_response") {
        setLogs((prevLogs) => [...prevLogs, data].slice(-100)); // Keep last 100 logs
      }
    });

    // Clean up on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [logs]);

  const getBackgroundColor = (type) => {
    switch (type) {
      case "innermonologue":
        return "bg-blue-100";
      case "planned_output":
        return "bg-green-100";
      case "systemaction":
        return "bg-yellow-100";
      case "info":
        return "bg-gray-100";
      default:
        return "bg-white";
    }
  };

  return (
    <div
      className="h-full bg-white rounded-lg overflow-hidden shadow-lg"
      style={{
        boxShadow:
          "inset 0 0 15px rgba(0, 0, 0, 0.5), 0 0 0 1px rgba(255, 255, 255, 0.1)",
      }}
    >
      <div className="relative bg-transparent rounded-lg overflow-hidden flex flex-col h-full">
        <div className="bg-gray-50 bg-opacity-25 border-b border-gray-200 p-2">
          <h2 className="text-lg font-light text-gray-800">nisa.inside</h2>
          <p className="text-sm font-light text-gray-400">
            transparency into llm output and actions
          </p>
        </div>
        <div
          className="p-4 h-[calc(100vh-200px)] overflow-y-auto"
          ref={logContainerRef}
        >
          {logs.map((log, index) => (
            <div
              key={index}
              className={`mb-2 p-2 rounded ${getBackgroundColor(log.type)}`}
            >
              <span className="font-bold">{log.type}</span>: {log.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UnderTheHood;
