import axios from "axios";

// Enum for supported ClientIDs
export enum ClientID {
  TEACHER = "teacher",
  COACH = "coach",
}

// Account information for the user
export enum AccountInfo {
  CLIENT_ID = "clientID",
  UID = "uid",
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
}

export const cacheClientID = (clientID: string): void => {
  if (clientID) {
    sessionStorage.setItem(AccountInfo.CLIENT_ID, clientID);
  } else {
    console.warn("No clientID provided to cache.");
  }
};

export const fetchAndCacheAccountInfo = async (
  accessToken: string,
): Promise<void> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/account/info`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const { client_id, uid, first_name, last_name } = response.data;

    if (Object.values(ClientID).includes(client_id)) {
      sessionStorage.setItem(AccountInfo.CLIENT_ID, client_id);
    } else {
      console.warn("Invalid client_id received:", client_id);
    }
    sessionStorage.setItem(AccountInfo.UID, uid);
    sessionStorage.setItem(AccountInfo.FIRST_NAME, first_name);
    sessionStorage.setItem(AccountInfo.LAST_NAME, last_name);
  } catch (error) {
    console.error("Failed to fetch and cache session info:", error);
  }
};

export const getCachedClientID = (): ClientID | null => {
  const clientID = sessionStorage.getItem(
    AccountInfo.CLIENT_ID,
  ) as ClientID | null;
  return clientID;
};

export const getCachedUID = (): string | null => {
  const uid = sessionStorage.getItem(AccountInfo.UID);
  if (!uid || uid.trim() === "") {
    return null;
  }
  return uid;
};

export const getCachedUserFullName = (): string | null => {
  const firstName = sessionStorage.getItem(AccountInfo.FIRST_NAME);
  const lastName = sessionStorage.getItem(AccountInfo.LAST_NAME);
  return firstName && lastName ? `${firstName} ${lastName}` : null;
};
