import axios from "axios";

export const fetchRssLink = async (
  teacherId: string,
  accessToken: string,
): Promise<string> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/rss/encode/${teacherId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const encryptedUid = response.data.encrypted_teacher_uid;
    return `${process.env.REACT_APP_API_URL}/rss/${encryptedUid}`;
  } catch (err) {
    console.error("Failed to fetch RSS link:", err);
    throw new Error("Could not fetch RSS link");
  }
};

// Enum for Grade
export enum Grade {
  KINDERGARTEN = "K",
  FIRST = "1",
  SECOND = "2",
  THIRD = "3",
  FOURTH = "4",
  FIFTH = "5",
  SIXTH = "6",
  SEVENTH = "7",
  EIGHTH = "8",
  HIGH_SCHOOL = "HS",
}

// Enum for Lesson Source
export enum LessonSource {
  ILLUSTRATIVE_MATHEMATICS = "illustrative_mathematics",
  CUSTOM = "custom",
  EUREKA_MATH = "eureka_math",
}

// Enum for Subjects
export enum Subjects {
  ELA = "English",
  MATH = "Mathematics",
  SCIENCE = "Science",
}

// PhoneNumber model
export interface PhoneNumber {
  phone_number: string;
}

// LessonOut model
export interface LessonOut {
  id: string;
  lesson_source: LessonSource;
  display_name: string;
  grade: Grade;
  curriculum_name?: string | null;
  module_name: string;
  topic_name: string;
  lesson_name: string;
}

// TeacherInfo model
export interface TeacherInfo {
  id: string;
  first_name: string;
  last_name: string;
  grades_taught: Grade[];
  last_lesson_id?: string | null;
  last_lesson_source?: LessonSource | null;
  phone_number: PhoneNumber;
  subjects_taught: Subjects[];
  active: boolean;
  last_lesson?: LessonOut | null;
}

/**
 * Fetches the list of all teachers.
 *
 * @param token - The authentication token.
 * @returns A promise that resolves to an array of TeacherInfo.
 */
export const fetchAllTeachers = async (
  token: string,
): Promise<TeacherInfo[]> => {
  const response = await axios.get<TeacherInfo[]>(
    `${process.env.REACT_APP_API_URL}/teacher/info`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};
