import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ClientID, AccountInfo } from "../utils/sessionUtils";

const NotFoundPage = () => {
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    // Check the role cached in sessionStorage
    const cachedClientID = sessionStorage.getItem(
      AccountInfo.CLIENT_ID,
    ) as ClientID | null;

    if (cachedClientID) {
      if (cachedClientID === "coach") {
        setRedirectPath("/");
      } else if (cachedClientID === "teacher") {
        setRedirectPath("/teacher");
      }
    }
  }, []);

  return (
    <div style={{ textAlign: "center", padding: "2rem" }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you're looking for doesn't exist.</p>
      {redirectPath ? (
        <Link to={redirectPath}>Go to your dashboard</Link>
      ) : (
        <Link to="/login">Go to Login</Link>
      )}
    </div>
  );
};

export default NotFoundPage;
